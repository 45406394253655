import React from "react"
import "../styles/styles.scss"
import { Helmet } from "react-helmet"

import LsPageComponent from "../components/lsPageComponent"

const LSPage = () => (
  <div>
    <Helmet title="Security-Alarm-KMU" defer={false}>
      <html lang="de" />
      <meta name="description" content="ALARM-Projekt -- Security Game 04 -- KI im Homeoffice -- Finde als Künstliche Intelligenz
Schwachstellen im Homeoffice." />
      <meta name="keywords" content="Game, Security, ALARM, Awareness, KMU, CEO Fraud, Spurensuche KI, Homeoffice" />
  </Helmet>
    <LsPageComponent 
      ls="4" 
      game="ls4"
      test="true"
      questionairs="false"
      motto="KI im HomeOffice"
      chapterUrl="https://client.gamebook.io/alarm-stories/live/index.html?episode=https://exg-gb-beta.s3.eu-west-1.amazonaws.com/file-uploads/exports/6142fe7676dc100013b7181c/6142fe9176dc100013b71821/Chapter_1_41.zip"/>
   </div>
)

export default LSPage
